<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="includeList">
      <component :is="Component" />
    </keep-alive>
  </router-view>
  <backgroundVideo v-show="agreement"></backgroundVideo>
</template>

<script>
import { reactive, toRefs, onMounted, watch } from "@vue/runtime-core";
import backgroundVideo from "./components/backgroundVideo.vue";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";

window.Toast = Toast;

export default {
  name: "App",
  components: {
    backgroundVideo,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      agreement: true,
      includeList: [],
    });
    watch(
      () => route.path,
      () => {
        // if (/iPhone|mac|iPod|iPad/i.test(navigator.userAgent)) {
        // document.title = route.path;
        // Toast.success(document.title);
        // }
        if (route.path == "/agreement") {
          state.agreement = false;
        } else {
          state.agreement = true;
        }
      }
    );
    watch(
      () => route,
      (newVal) => {
        if (
          newVal.meta.keepAlive &&
          state.includeList.indexOf(newVal.name) === -1
        ) {
          state.includeList.push(newVal.name);
        }
      },
      { deep: true }
    ); // 开启深度监听
    onMounted(() => {
      const ue = window.ue;
      // ue.interface.setFPS = (val) => {
      //   Toast.fail(parseFloat(val) + "setFPS");
      // };
      ue.interface.setUser = (val) => {
        Toast.fail(parseFloat(val) + "setUser");
        if (val && sessionStorage.getItem("switch") !== "true") {
          sessionStorage.setItem("token", val);
          router.push({ path: "/loginFrist" });
        } else {
          router.push({ path: "/login" });
        }
      };
    });
    return { ...toRefs(state) };
  },
};
</script>

<style>
@import "./assets/font/font.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: PingFang;
}
</style>
